import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {Bar} from "react-chartjs-2";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import firebase from "./firebase";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({});
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default withStyles(styles)(
    class Users extends React.Component {

        static propTypes = {
            user: PropTypes.object,
        };

        constructor(props) {
            super(props);
            this.state = {
                email:'',
                error: false,
                emailLabel: 'E-mail',
                success: false,
                loading: false,
                campaignID: '',
                data: [],
                dataFiltered: [],
                listener: '',
                dates: [],
                values: [],
                dataRend: {},
                dataRend2: {},
                traffic: 0,
                trafficUsers: 0,
                registers: 0,
                conversions: 0,
                name: '',
                brandShare: 0,
                brandShareRegister: 0,
                consumerShareRegister: 0,
                consumerShare: 0,
                pocs: {},
                pocsData: {},
            };
        }

        handleChangeEmail = (event) => {
            this.setState({email: event.target.value, error: false, emailLabel: 'E-mail', success: false});
        };

        handleChange = name => (event) => {
            this.setState({
                [name]: event.target.value,
                error: false,
                emailLabel: 'E-mail',
                success: false
            });
        };
        componentDidMount() {

            var db = firebase.firestore();

            var context =this;

            var listener = db.collection('campaigns').where('adminUID','==', this.props.user.uid).get().then(function (camps) {


                camps.forEach(function (camp) {
                    context.setState({
                        traffic: camp.data().traffic,
                        trafficUsers: camp.data().trafficUsers,
                        registers: camp.data().registers,
                        conversions: camp.data().conversions,
                        name: camp.data().nam,
                        brandShare: camp.data().brandShare,
                        consumerShare: camp.data().consumerShare,
                        brandShareRegister: camp.data().brandShareRegister,
                        consumerShareRegister: camp.data().consumerShareRegister,
                        campaignID: camp.id,
                        facebookVis: camp.data().facebookVis,
                        facebookReg: camp.data().facebookReg,
                        instagramVis: camp.data().instagramVis,
                        instagramReg: camp.data().instagramReg,
                        storiesVis: camp.data().storiesVis,
                        storiesReg: camp.data().storiesReg,
                        mailingVis: camp.data().mailingVis,
                        mailingReg: camp.data().mailingReg,
                        pocs: camp.data().pocs,
                        pocsData: camp.data().pocsData,
                    });
                })
            })
        }

        resend = () => {
            if (validateEmail(this.state.email)){
                this.setState({
                    loading: true,
                });
                var data = {
                    email: this.state.email,
                    campaign: this.state.campaignID,
                };
                fetch('https://us-central1-mktviral.cloudfunctions.net/resendToWithGmail', {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(data), // data can be `string` or {object}!
                    headers:{
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    res.json().then(val => {

                        console.log(val);

                        if (val){
                            this.setState({
                                emailLabel: 'Success',
                                success: true,
                                email: '',
                                loading: false,
                            })
                        }else {
                            this.setState({
                                emailLabel: 'User doesn\'t exist',
                                error: true,
                                loading: false,
                            })
                        }

                    }).catch(function (error) {
                        console.log(error)
                    });
                }).catch(function (error) {
                    console.log(error)
                });
            }else {
                this.setState({
                    emailLabel: 'Invalid e-mail',
                    error: true,
                    loading: false,
                })
            }


        };

        render() {
            const theme = createMuiTheme({
                palette: {
                    primary: {
                        // light: will be calculated from palette.primary.main,
                        main: this.state.success ? '#2cf338' : '#4457F3' ,
                        // dark: will be calculated from palette.primary.main,
                        // contrastText: will be calculated to contrast with palette.primary.main
                    }
                },
                typography: { useNextVariants: true },
            });
            const {classes} = this.props;
            return (
                <MuiThemeProvider theme={theme}>
                <div className={"container"}>
                    <p className={"title"}>Users</p>
                    <div className={"graphsContainer"}>
                        <Paper elevation={1} className={"paperRendimiento"}>
                            <div className={'rendimientoCointainer'}>
                                <p className={"title"}>Resend e-mail </p>
                                <TextField
                                    value={this.state.email}
                                    onChange={this.handleChangeEmail}
                                    error={this.state.error}
                                    label={this.state.emailLabel}
                                    className="loginEmail resendEmailInput"
                                    name="email"
                                    margin="normal"
                                    variant="outlined"
                                    color={'secondary'}
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                {!this.state.loading &&  <Button variant="contained" color="primary" className={"loginButton resendButton"} onClick={this.resend}>
                                    Send
                                </Button> }

                                {this.state.loading && <CircularProgress className={'loginButton progressCircular'} />}

                            </div>

                        </Paper>
                    </div>

                </div>
                </MuiThemeProvider>
            )
        }
    })