import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {Line} from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2'
import {Pie} from 'react-chartjs-2';
import {Bar} from 'react-chartjs-2';
import firebase from './firebase';
import RankingTable from "./RankingTable";
import { saveAs } from 'file-saver';

function getPocName(number, pocsList) {


    for (var i = 0; i< pocsList.length; i++){
        let poc = pocsList[i];
        if (poc.co === number){
            return poc.nombre;
        }
    }

    return false;
}
function getPocCity(number, pocsList) {

    for (var i = 0; i< pocsList.length; i++){
        let poc = pocsList[i];
        if (poc.co === number){
            return poc.ciudad;
        }
    }

    return false;
}

function fixMetrics() {
    var db = firebase.firestore();
    db.collection('users').where('campaign', '==', 'patagoniaoct')
        .get().then(function (docs) {
            let brandShareRegister = 0;
            let consumerShareRegister = 0;
            docs.forEach(function (user) {
                let userData = user.data();
                if (userData.friendUID){
                    consumerShareRegister ++;
                }
                else {
                    brandShareRegister ++;
                }
            });
            console.log('size: ', docs.size);
            console.log('Brand: ', brandShareRegister);
            console.log('Consumer: ', consumerShareRegister);
    })
}

function updatePocsData() {
    var db = firebase.firestore();
    var pocsData =[{nombre:"Nordelta",co:"1NA",address:"Centro Comercial Nordelta - Av. de los lagos 7008",ciudad:"Nordelta",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"Maschwitz",co:"MA2",address:"Mendoza 1748, Ing, Maschwitz",ciudad:"Ing. Maschwitz",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"Mexico y Peru",co:"MP3",address:"Esquina de México y Perú",ciudad:"Monserrat",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Pasteur y Viamonte",co:"PV4",address:"Esquina de Pasteur y Viamonte",ciudad:"Balvanera",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Paraguay 1448",co:"PR5",address:"Paraguay 1448 entre Uruguay y Paraná",ciudad:"Recoleta",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Callao",co:"CA6",address:"Callao 650",ciudad:"Balvanera",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Paraguay 1900",co:"PA7",address:"Paraguay 1900",ciudad:"Recoleta",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Distrito Arcos",co:"DA8",address:"Paraguay 4979",ciudad:"Palermo",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Arenales",co:"AE9",address:"Arenales 2707",ciudad:"Recoleta",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Av de Mayo",co:"AM1",address:"Av. De Mayo 752",ciudad:"Monserrat",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Buen Pastor",co:"BP2",address:"Avenida Hipolito Yrigoyen 325, Paseo del Buen Pastor",ciudad:"Cordoba",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Recta Martinoli",co:"RM3",address:"Av.Recta Martinolli 8627",ciudad:"Villa Belgrano",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"54 y 5",co:"LP4",address:"54 nº 495",ciudad:"La Plata",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"7 y 59",co:"LP5",address:"Calle 7 y Calle 59, esquina",ciudad:"La Plata",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"Fisherton Mall",co:"FM6",address:"ALBERTO J. PAZ 1065",ciudad:"Rosario",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Riobamba y Santa Fe",co:"RS7",address:"Riobamba 1125",ciudad:"Barrio Norte",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Reconquista",co:"RE8",address:"Reconquista 1056",ciudad:"Microcentro",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Rotonda Fisherton",co:"RF9",address:"Wilde 235 Bis",ciudad:"Rosario",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Gurruchaga",co:"GU1",address:"Gurruchaga 1437",ciudad:"Palermo",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"DINO",co:"DN1",address:"Rodriguez del busto 4086",ciudad:"Alto Verde",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Palmares Open Mall",co:"PA2",address:"San Martin Sur 2875",ciudad:"Godoy Cruz",zona:"MENDOZA",region:"MENDOZA"},
        {nombre:"Cordoba Shopping",co:"CS3",address:"José de Goyechea 2851",ciudad:"Cordoba",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Bar Cordoba",co:"BC4",address:"Belgrano 840",ciudad:"Cordoba",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Alto Rosario",co:"AR5",address:"Junin 501",ciudad:"Rosario",zona:"LITORAL",region:"LITORAL"},
        {nombre:"20 y 50",co:"LP6",address:"Calle 20 nro. 919 e/ 50 y 51",ciudad:"La Plata",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"City Bell",co:"CB7",address:"473 bis #136 esq. Diagonal 9 de Julio",ciudad:"City Bell",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"Chacras de Coria",co:"CC8",address:"Mitre 1440",ciudad:"Chacras de Coria",zona:"MENDOZA",region:"MENDOZA"},
        {nombre:"Arenales y Billinghurst",co:"AB9",address:"Arenales esquina Billinghurst",ciudad:"CABA",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Garage italia",co:"GA1",address:"Italia 855",ciudad:"Rosario",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Sucre",co:"SU1",address:"Sucre 658",ciudad:"CABA",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Remeros Plaza",co:"RP2",address:"Av. Sta. María de las Conchas 4711, Rincón de Milberg",ciudad:"Tigre",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"Lomitas",co:"LM3",address:"Mitre 286",ciudad:"Lomas de Zamora",zona:"BUENOS AIRES",region:"GBA"},
        {nombre:"Carrodillas",co:"CA4",address:"San Martin 6864, Carrodilla, Lujan de Cuyo",ciudad:"Lujan de Cuyo",zona:"MENDOZA",region:"MENDOZA"},
        {nombre:"Corrientes",co:"CR5",address:"Avenida Costanera General San Martín y Tomás Edison",ciudad:"Corrientes",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Tucuman - Yerba Buena",co:"YB6",address:"Luis lobo de la vega esquina presidente peron",ciudad:"Yerba Buena",zona:"NORTE",region:"NORTE"},
        {nombre:"Bar Rosario",co:"BR7",address:"Jujuy 2204",ciudad:"Rosario",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Santa Fe",co:"SF8",address:"Bv. Pellegrini 2421",ciudad:"Santa Fe",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Villa Urquiza",co:"VU9",address:"Andonaegui y La pampa (La Pampa 5501)",ciudad:"CABA",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Rosario - La Florida",co:"LF1",address:"Av. Eudoro Carrasco 2035",ciudad:"Rosario",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Carlos Paz",co:"CP2",address:"Av libertad 253",ciudad:"Carlos paz",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Caballito",co:"CA3",address:"Alberdi 1402",ciudad:"Caballito",zona:"BUENOS AIRES",region:"CABA"},
        {nombre:"Plaza de la Musica",co:"PM4",address:"Mendoza 471",ciudad:"Cordoba",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Valle Escondido",co:"VE5",address:"Av. Republica de China km 10,12 - Centro comerical ACAECE 5000",ciudad:"Cordoba",zona:"CORDOBA",region:"CORDOBA"},
        {nombre:"Tucuman - El Abasto",co:"EA6",address:"Miguel Lillio 365",ciudad:"San Miguel de Tucuman",zona:"NORTE",region:"NORTE"},
        {nombre:"Mendoza Shopping",co:"MS7",address:"Av, Acceso Este 3280",ciudad:"Mendoza",zona:"MENDOZA",region:"MENDOZA"},
        {nombre:"Comodoro",co:"CM8",address:"Av. Manuel Quintana 151",ciudad:"Comodoro Rivadavia",zona:"COMODORO",region:"CHUBUT"},
        {nombre:"San Juan",co:"SJ9",address:"Castelli 500 - Av. Circunvalación",ciudad:"San Juan",zona:"LITORAL",region:"LITORAL"},
        {nombre:"Recoleta",co:"RA1",address:"Vicente López 2229",ciudad:"Recoleta",zona:"BUENOS AIRES",region:"CABA"}];
    db.collection('campaigns').doc('patagoniaoct').update({pocsData: pocsData}).then(function () {
        console.log('subido')
    })

}

function checkIfFB() {
    var db = firebase.firestore();
    db.collection('users').where('campaign', '==', 'patagoniaoct').get().then(function (users) {
        let count = 0;
        users.forEach(function (user) {
            if (user.data().fid){
                count ++;
            }
        })
        console.log('Cuenta: ', count);
        console.log('Total: ', users.size)
    })
}

function exportToJson(objectData) {
    let filename = "export.json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

function getNonValidated(campID) {
    const db = firebase.firestore();
    db.collection('users').where('campaign', '==', campID).where('validated', '==', false).get().then(function (snap) {
        let arr = [];
        snap.forEach(function (user) {
            let userData = user.data();
            let userFinal = {
                nombre: userData.name,
                apellido: userData.lastName,
                id: user.id,
                email: userData.email,
                redimio: userData.validated,
                creadoEn: userData.createdAt.toDate().toString(),
                birthday: userData.birth,
                documento: userData.dni,
                facebookId: userData.fid ? userData.fid : '',
                friendID: userData.friendUID,
                compartio: userData.shareToRegister,
                redimioEn: userData.redeemedAt ? userData.redeemedAt.toDate().toString() : '',
                pocCode: userData.pocCode ? userData.pocCode : '',
            };
            arr.push(userFinal)
        });
        console.log(arr);
        var data = {
            key: 'value'
        };
        var fileName = campID + 'NonRedeems.json';

// Create a blob of the data
        var fileToSave = new Blob([JSON.stringify(arr, undefined, 2)], {
            type: 'application/json',
            name: fileName
        });

// Save the file
        saveAs(fileToSave, fileName);

    })
}

function getInfluShares() {
    const db = firebase.firestore();
    let arr = [
        'KfwvxneFemhLuXzajbEz',
        '4RBvRLPhywVDqaTeon4I',
        '93LdoMekzV7d1Y4e6GfK',
        'DaGmu8qGxyimdc9KVJnj',
        '66W3w5mll0DbfhDFZRsI',
        'L2w4onrZ0eFCWX78EJ9O',
        'UNtvgCnfH74Ajd2sx6g5',
        '6PzLIDcMeahYSGBNyBZ6',
        '1Q5sZ6TOMkP2tM7jcMcb',
        'cpVnMWNIlMgdpzFKoZt8'
    ];
    let arr2 = [];
    arr.forEach(function (id) {
        db.collection('users').doc(id).get().then(function (doc) {
            arr2.push({name: doc.data().name + ' ' + doc.data().lastName, shares: doc.data().shareToRegister});
        })
    });
    console.log(arr2);

    /*setTimeout(function () {
        exportToJson(arr2)
    },3000)
*/
}

function getDatabase(campID) {
    var db = firebase.firestore();
    db.collection('users').where('campaign', '==', campID).get().then(function (users) {
        let data = [];
        users.forEach(function (user) {
            let userData = user.data();
            let userFinal = {
                nombre: userData.name,
                apellido: userData.lastName,
                id: user.id,
                email: userData.email,
                redimio: userData.validated,
                birthday: userData.birth,
                documento: userData.dni,
                facebookId: userData.fid ? userData.fid : '',
                friendID: userData.friendUID,
                compartio: userData.shareToRegister,
            };
            data.push(userFinal);
        });
        var fileName = campID +'Database.json';

// Create a blob of the data
        var fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
            type: 'application/json',
            name: fileName
        });

// Save the file
        saveAs(fileToSave, fileName);
    })
}

function getPOCDataJson(pocs, pocsData) {
    let arrForRed = [];
    for (var prop in pocs) {
        if (Object.prototype.hasOwnProperty.call(pocs, prop)) {
            arrForRed.push({nombre: getPocName(prop, pocsData), poc: prop ,redeems: pocs[prop]});
        }
    }
    var fileName = 'pocsData.json';

// Create a blob of the data
    var fileToSave = new Blob([JSON.stringify(arrForRed, undefined, 2)], {
        type: 'application/json',
        name: fileName
    });

// Save the file
    saveAs(fileToSave, fileName);
}

export default class Dashboard extends React.Component {

    static propTypes = {
        user: PropTypes.object,

    };

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            campaignID: '',
            data: [],
            dataFiltered: [],
            listener: '',
            dates: [],
            values: [],
            dataRend: {},
            dataRend2: {},
            traffic: 0,
            trafficUsers: 0,
            registers: 0,
            conversions: 0,
            name: '',
            brandShare: 0,
            brandShareRegister: 0,
            consumerShareRegister: 0,
            consumerShare: 0,
            pocs: {},
            pocsData: {},

        };
    }
    componentWillUnmount() {
        if (this.state.listener){
            this.state.listener()
        }
    }



    componentDidMount(){
        var db = firebase.firestore();

        var context =this;
        var listener = db.collection('campaigns').where('adminUID','==', this.props.user.uid).onSnapshot(function (camps) {
            var arrAgeDis = [];
            var labels = [];
            var qs = [];

            var labels2 = [];
            var qs2 = [];

            camps.forEach(function (camp) {
                context.setState({traffic: camp.data().traffic, trafficUsers: camp.data().trafficUsers, registers: camp.data().registers,
                    conversions: camp.data().conversions, name: camp.data().nam, brandShare: camp.data().brandShare, consumerShare: camp.data().consumerShare,
                    brandShareRegister: camp.data().brandShareRegister, consumerShareRegister: camp.data().consumerShareRegister, campaignID: camp.id,
                    facebookVis: camp.data().facebookVis, facebookReg: camp.data().facebookReg, instagramVis: camp.data().instagramVis, instagramReg: camp.data().instagramReg,
                    storiesVis: camp.data().storiesVis, storiesReg: camp.data().storiesReg, mailingVis: camp.data().mailingVis, mailingReg: camp.data().mailingReg,
                    pocs: camp.data().pocs, pocsData: camp.data().pocsData,
                });
                var pocs = camp.data().pocs;
                var pocsArr = camp.data().pocsData;

                var pocNumbers = Object.keys(pocs);
                pocNumbers.forEach(function (poc) {
                   labels.push(getPocName(poc, pocsArr))
                });

                let pocCities = [];

                pocNumbers.forEach(function (poc) {
                    if (!pocCities.includes(getPocCity(poc, pocsArr))){
                        pocCities.push(getPocCity(poc, pocsArr))
                    }
                });


                let finalObj = {};
                pocCities.forEach(function (pocCity) {
                   pocNumbers.forEach(function (pocNum) {
                       if(getPocCity(pocNum, pocsArr) === pocCity){
                           if (finalObj[pocCity]){
                               finalObj[pocCity] = finalObj[pocCity] + pocs[pocNum]
                           }else {
                               finalObj[pocCity] = pocs[pocNum]
                           }
                       }
                   })
                });


                var count = 0;
                qs = Object.values(pocs);
                qs.forEach(function (c) {
                    count = count + c;
                });


                labels2 = Object.keys(finalObj);
                qs2 = Object.values(finalObj);

                arrAgeDis[0] = camp.data().minusVeinte;
                arrAgeDis[1] = camp.data().veintes;
                arrAgeDis[2] = camp.data().treintas;
                arrAgeDis[3] = camp.data().cuarentas;
                arrAgeDis[4] = camp.data().cincuentas;
                arrAgeDis[5] = camp.data().sesentas;
                arrAgeDis[6] = camp.data().setentas;
                arrAgeDis[7] = camp.data().ochentas;
                arrAgeDis[8] = camp.data().masNoventas;
            });
            const data = (canvas) => {

                const ctx = canvas.getContext("2d");
                var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
                gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.3)");
                gradientFill.addColorStop(1, "rgba(68, 87, 243, 1)");
                return{
                    labels: labels2,
                    datasets: [
                        {
                            label: '',
                            fill: true,
                            lineTension: 0.3,
                            backgroundColor: gradientFill,
                            borderColor: '#4457F3',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(68, 87, 243, 1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#4457F3',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: qs2,
                        }
                    ]
                };
            };
            const data2 = (canvas) => {

                const ctx = canvas.getContext("2d");
                var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
                gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.3)");
                gradientFill.addColorStop(1, "rgba(68, 87, 243, 1)");
                return{
                    labels: ['Menos de 20', '20 a 30','30 a 40', '40 a 50', '50 a 60', '60 a 70', '70 a 80', '80 a 90', 'Mas de 90'],
                    datasets: [
                        {
                            label: '',
                            fill: true,
                            lineTension: 0.3,
                            backgroundColor: gradientFill,
                            borderColor: '#4457F3',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(68, 87, 243, 1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#4457F3',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: arrAgeDis,
                        }
                    ]
                };
            };
            context.setState({dataRend: data, dataRend2: data2})
        });
       /* db.collection('campaigns').where('adminUID','==', this.props.user.uid).get().then(function (camps) {

        }).catch(function (error) {

        });*/

       this.setState({listener: listener})


    }

    render(){

        const data2 = (canvas) => {

            return {

                labels: ['labels'],
                datasets: [{
                    data: [1],
                    backgroundColor: [
                        '#4457F3',
                        '#DE3BCB',
                        '#FF4995',
                        '#FF8566',
                        '#FFC351',
                        '#F9F871',
                        '#009F71',
                        '#B200FF',
                        '#E10027',
                        '#A097CD',
                        '#009CA7',
                    ],
                    hoverBackgroundColor: [
                        '#4457F3',
                        '#DE3BCB',
                        '#FF4995',
                        '#FF8566',
                        '#FFC351',
                        '#F9F871',
                        '#009F71',
                        '#B200FF',
                        '#E10027',
                        '#A097CD',
                        '#009CA7',
                    ]
                }]
            }

        };


        return(


            <div className={"container"}>
                <p className={"title"}>Dashboard</p>

                <div className={"graphsContainer"}>
                    <Paper elevation={1} className={"paperRendimiento"}>
                        <div className={'rendimientoCointainer'}>
                            <p className={"title"}>Redeems by zone </p>
                            <Bar className={'chart'} data={this.state.dataRend} options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },

                                        ticks: {
                                            autoSkip: true,
                                           /* maxRotation: window.innerWidth < 960 ? 90 : 0,
                                            minRotation: window.innerWidth < 960 ? 90 : 0,*/
                                            maxRotation: 0,
                                            minRotation: 0,
                                        }
                                    }],
                                    yAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },
                                        ticks: {
                                            display: false //this will remove only the label
                                        }
                                    }]
                                }
                            }}/>
                        </div>

                    </Paper>
                    <div className={'metricsWrapper'}>
                        <Paper elevation={1} className={"paperDashMetrics"}>
                            <div className={"rendimientoCointainer metricContainer"}>
                                <p className={"title metricsTitle"}>Visitors</p>
                                <p className={'metricP'}>{this.state.trafficUsers}</p>

                            </div>
                        </Paper>
                        <Paper elevation={1} className={"paperDashMetrics"}>
                            <div className={"rendimientoCointainer metricContainer"}>
                                <p className={"title metricsTitle"}>Registers</p>
                                <p className={'metricP'}>{this.state.registers}</p>
                            </div>
                        </Paper>
                        <Paper elevation={1} className={"paperDashMetrics"}>
                            <div className={"rendimientoCointainer metricContainer"}>
                                <p className={"title metricsTitle"}>Redeems</p>
                                <p className={'metricP'}>{this.state.conversions}</p>
                            </div>
                        </Paper>

                    </div>
                    <Paper elevation={1} className={"paperDashMetrics conversions"}>
                        <div className={"rendimientoCointainer metricContainer"}>
                            <p className={"title metricsTitle"}>Register Rate</p>
                            <p className={'metricP'}>{(this.state.registers/this.state.trafficUsers * 100).toFixed(2)+ '%'}</p>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperDashMetrics conversionsRate"}>
                        <div className={"rendimientoCointainer metricContainer"}>
                            <p className={"title metricsTitle"}>Redeem Rate</p>
                            <p className={'metricP'}>{(this.state.conversions/this.state.registers * 100).toFixed(2)+ '%'}</p>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperDashMetrics conversionsRateLast"}>
                        <div className={"rendimientoCointainer metricContainer"}>
                            <p className={"title metricsTitle"}>From Brand</p>
                            <p className={'metricP'}>{this.state.brandShareRegister}</p>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperDashMetrics conversions"}>
                        <div className={"rendimientoCointainer metricContainer"}>
                            <p className={"title metricsTitle"}>Brand Awarness</p>
                            <p className={'metricP'}>{(this.state.brandShareRegister/this.state.registers * 100).toFixed(2)+ '%'}</p>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperDashMetrics conversionsRate"}>
                        <div className={"rendimientoCointainer metricContainer"}>
                            <p className={"title metricsTitle"}>Consumer Engagement</p>
                            <p className={'metricP'}>{(this.state.consumerShareRegister/this.state.registers * 100).toFixed(2)+ '%'}</p>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperDashMetrics conversionsRateLast"}>
                        <div className={"rendimientoCointainer metricContainer"}>
                            <p className={"title metricsTitle"}>From Consumer</p>
                            <p className={'metricP'}>{this.state.consumerShareRegister}</p>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperRendimiento"}>
                        <div className={'rendimientoCointainer'}>
                            <p className={"title"}>Age distribution</p>
                            <Bar className={'chart'} data={this.state.dataRend2} options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },

                                        ticks: {
                                            autoSkip: true,
                                            /* maxRotation: window.innerWidth < 960 ? 90 : 0,
                                             minRotation: window.innerWidth < 960 ? 90 : 0,*/
                                            maxRotation: 0,
                                            minRotation: 0,
                                        }
                                    }],
                                    yAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },
                                        ticks: {
                                            display: false //this will remove only the label
                                        }
                                    }]
                                }
                            }}/>
                        </div>

                    </Paper>
                    <div className={'metricsWrapper'}>
                        <Paper elevation={1} className={"paperDashMetrics"}>
                            <div className={"rendimientoCointainer metricContainer"}>
                                <p className={"title metricsTitle"}>From Facebook</p>
                                <p className={'metricP'}>{this.state.facebookReg}</p>

                            </div>
                        </Paper>
                        <Paper elevation={1} className={"paperDashMetrics"}>
                            <div className={"rendimientoCointainer metricContainer"}>
                                <p className={"title metricsTitle"}>From Instagram</p>
                                <p className={'metricP'}>{this.state.instagramReg + this.state.storiesReg}</p>
                            </div>
                        </Paper>
                        <Paper elevation={1} className={"paperDashMetrics"}>
                            <div className={"rendimientoCointainer metricContainer"}>
                                <p className={"title metricsTitle"}>From Mailing</p>
                                <p className={'metricP'}>{this.state.mailingReg}</p>
                            </div>
                        </Paper>

                    </div>

                    <RankingTable campaign={this.state.campaignID}/>



                </div>




            </div>
        )
    }
}