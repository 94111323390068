import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import firebase from './firebase'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LoadBackComponent from "./LoadBackComponent";
import LoginComponent from "./LoginComponent";
import AppComponent from "./AppComponent";

const generateClassName = createGenerateClassName();
const jss = create({
    ...jssPreset(),
    // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
    insertionPoint: document.getElementById('jss-insertion-point'),
});


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login: false,
            user: {},
            value: 0,
            errorLogin: false,
            email: '',
            pass: '',
            emailLabel:'Usuario'
        };
    }

    handleLogIn = (user, pass) => {
        var context = this;
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(function() {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                return firebase.auth().signInWithEmailAndPassword(user, pass);
            })
            .catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode + ' ' + errorMessage );
                context.setState({errorLogin: true, emailLabel: errorMessage})

            });
    };

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value, errorLogin: false, emailLabel: 'Usuario'});
    };
    handleChangePass = (event) => {
        this.setState({pass: event.target.value, errorLogin: false, emailLabel: 'Usuario'});
    };

    componentDidMount(){
        var contextLala = this;

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                contextLala.setState({login: true, user: user, value: 1});
            } else {
                // User is signed out.
                contextLala.setState({login: false, value: 2});
            }
        });
    }

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <JssProvider jss={jss} generateClassName={generateClassName}>
                <MuiThemeProvider theme={theme}>
                    <div className="App">
                        {this.state.value === 0 &&  <LoadBackComponent/>}
                        {this.state.value === 1 &&  <AppComponent user={this.state.user}/>}
                        {this.state.value === 2 && <LoginComponent error={this.state.errorLogin} emailLabel={this.state.emailLabel} email={this.state.email} pass={this.state.pass} handleChangeEmail={this.handleChangeEmail} handleChangePass={this.handleChangePass} handleLogin={(user, pass) => this.handleLogIn(user, pass)}/>}
                    </div>
                </MuiThemeProvider>
            </JssProvider>

        );
    }
}

export default App;
