import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AppBarComponent from "./AppBarComponent";
import Dashboard from "./Dashboard";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Users from "./Users";


export default class AppComponent extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            search: '',
            value: 0,
        };
    }

    handleOpen = (i) => {
        if (i===0){
            this.setState({ openInvestment: true });
        } else if (i===1){
            this.setState({ openClient: true });
        }
        else if (i===2){
            this.setState({ openTrade: true });
        }
        else if (i===3){
            this.setState({ openObj: true });
        }
        else if (i===6){
            this.setState({ openChangePass: true });
        }
        else if (i===7){
            this.setState({openNewAdmin: true})
        }
        else if (i===8){
            this.setState({openExtraCoin: true})
        }
        else if (i===9){
            this.setState({openNewPortfolio: true})
        }

    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };
    handleSearch = (event) => {
        this.setState({search: event.target.value})
    };
    clearTyped = () => {
        this.setState({search: ''})
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };
    componentDidMount() {

    }

    render() {
        const { value } = this.state;
        return (
            <div>
                <AppBarComponent user={this.props.user} handleDrawerToggle={this.handleDrawerToggle}  handleSearch={this.handleSearch} searchVal={this.state.search} handleOpenModals={this.handleOpen}/>
                <AppBar className={'tabsDash'} position="static">
                    <Tabs className={'tabs'} value={value} onChange={this.handleChange}
                          classes={{
                              indicator: 'tabsTradesIndicator'
                          }}>
                        <Tab label="Dashboard" />
                        <Tab label="Usuarios" />
                    </Tabs>
                </AppBar>
                {this.state.value === 0 && <Dashboard user={this.props.user}/> }
                {this.state.value === 1 && <Users user={this.props.user}/> }
            </div>
        )
    }
}