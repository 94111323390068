import firebase from "firebase";

let config = {
    apiKey: "AIzaSyAJ3PfFl_zNjBGKt0lXyvUqTxz-Z7aruM8",
    authDomain: "mktviral.firebaseapp.com",
    databaseURL: "https://mktviral.firebaseio.com",
    projectId: "mktviral",
    storageBucket: "mktviral.appspot.com",
    messagingSenderId: "879021023419"
};
firebase.initializeApp(config);

export default firebase;
