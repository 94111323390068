import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import firebase from './firebase';
import Paper from "@material-ui/core/Paper";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function isOdd(num) { return num % 2;}
function timestampToDateString(timestamp) {
    var date = new Date(timestamp * 1000);
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var day = date.getDate();

    return day + '/' + month + '/' + year;


}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;
        const rows = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
            { id: 'shareToRegister', disablePadding: false, label: 'Compartio' },
            { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },


        ];
        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class RankingTable extends React.Component {
    state = {
        order: 'desc',
        orderBy: 'shareToRegister',
        selected: [],
        data: [],
        dataFiltered: [],
        listener: '',
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    componentDidMount() {

        var db = firebase.firestore();
        var context = this;

        if (this.props.campaign){
            var listener = db.collection('users').where('campaign', '==', this.props.campagin).orderBy("shareToRegister").limit(10)
                .onSnapshot(function (snap) {
                    var arrExp = [];

                    snap.forEach(function (expense) {
                        /* var newExp = {
                             type: expense.data().type,
                             name: expense.data().name,
                             date: expense.data().date,
                             quantity: expense.data().quantity,
                             quantityUsd: expense.data().quantityUsd,
                             from: expense.data().from,
                             id: expense.id,
                         };
                         arrExp.push(newExp);*/
                    });
                    context.setState({data: arrExp, dataFiltered:arrExp})
                });
            this.setState({listener: listener});
        }


    }

    componentWillReceiveProps(nextProps, nextContext) {
        /*if(nextProps.search){
            var filtExp = [];
            this.state.data.forEach(function (exp) {
                if (exp.name.toLowerCase().includes(nextProps.search.toLowerCase())){
                    filtExp.push(exp);
                }
            });
            this.setState({dataFiltered: filtExp})
        }
        else {
            this.setState({dataFiltered: this.state.data})
        }*/

        if (nextProps.campaign !== this.props.campaign){

            var db = firebase.firestore();
            var context = this;
            var listener = db.collection('users').where('campaign', '==', nextProps.campaign).orderBy("shareToRegister", "desc").limit(10)
                .onSnapshot(function (snap) {
                    var arrExp = [];

                    snap.forEach(function (user) {
                        var userData = user.data();
                        console.log(userData);
                        userData.id = user.id;
                        /* var newExp = {
                             type: expense.data().type,
                             name: expense.data().name,
                             date: expense.data().date,
                             quantity: expense.data().quantity,
                             quantityUsd: expense.data().quantityUsd,
                             from: expense.data().from,
                             id: expense.id,
                         };
                         arrExp.push(newExp);*/
                        arrExp.push(userData)
                    });
                    context.setState({data: arrExp, dataFiltered:arrExp})
                });
            this.setState({listener: listener});
        }
    }


    componentWillUnmount() {
        if (this.state.listener){
            this.state.listener();
        }
    }



    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { dataFiltered, order, orderBy, selected, } = this.state;


        return (
            <div>
                <p className={"title"}>Top influencers</p>
                <Paper className={classes.root}>
                    <div className={classes.tableWrapper}>
                        <Table aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={dataFiltered.length}
                            />



                            <TableBody className={'tableBody'}>

                                {stableSort(dataFiltered, getSorting(order, orderBy))
                                    .map((n, idx) => {
                                        const isSelected = this.isSelected(n.id);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={idx}
                                                className={classes.row}
                                                selected={isSelected}
                                            >

                                                <TableCell component="th" scope="row">
                                                    {n.name + ' ' + n.lastName}
                                                </TableCell>
                                                <TableCell  component="th" scope="row">
                                                    {n.shareToRegister}
                                                </TableCell>
                                                <TableCell  component="th" scope="row">
                                                    {n.email}
                                                </TableCell>



                                            </TableRow>
                                        );
                                    })}


                            </TableBody>
                        </Table>
                    </div>
                </Paper>


            </div>

        );
    }
}

RankingTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RankingTable);